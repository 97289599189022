<template lang="pug">
span(:class='containerClass')
  Link(
    v-if='isLink'
    :class='textClass'
    :href='href'
    :target='target'
    :title='titleText'
    :to='to'
  ) {{ text }}
  span(
    v-else
    :class='textClass'
    :title='titleText'
  ) {{ text }}
</template>

<script>
import { Link } from './Link.vue'

export const TableText = {
  props: {
    enabled: {
      type: Boolean,
      default: true,
    },
    href: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
    truncated: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Link,
  },
  computed: {
    containerClass() {
      return { 'table-text': this.enabled }
    },
    isLink() {
      return this.href || this.to
    },
    target() {
      return this.href ? '_blank' : ''
    },
    textClass() {
      return { 'table-text-overflow': this.enabled && this.truncated }
    },
    titleText() {
      return this.title || this.text
    },
  },
}
export default TableText
</script>
