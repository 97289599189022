import { getCurrentUser } from '../../utilities/getCurrentUser'
import {
  getPreferences,
  getUserReminders,
  updatePreferences,
} from '../actions'

import {
  get as getUserMyCampaigns,
  manage as manageMyCampaigns,
} from '../../users/actions/myCampaigns'
import {
  addCampaigns as addCampaignsToSpendTable,
  getCampaignIds as getSpendTableCampaignIds,
  removeCampaign as removeFromSpendTable,
} from '../../spendTable/actions'
import log from '../../utilities/log'
import controlFlow from '../../../utilities/controlFlow'
import { matchAccessWith } from '@/auth/matchAccessWith'
import { nonAuthorizationPaths } from '@/auth/constants'

const logger = log('CurrentUser')
const isNonAuthorizationPath = path => nonAuthorizationPaths.includes(path)

export const CurrentUser = {
  state: {
    access: null,
    email: null,
    experimentalFeaturesEnabled: false,
    isAdmin: false,
    isLoaded: false,
    limitFeatures: false,
    myCampaignIds: [],
    myCampaignIdsCheckedAt: null,
    spendTableCampaignIds: [],
    spendTableCampaignIdsCheckedAt: null,
    sifiId: null,
    preferences: null,
    processId: null,
    campaignReminders: [],
  },
  getters: {
    isAuthorizedFor: ({ access, isAdmin }) => ({ method = 'client', path }) =>
      isAdmin ||
      isNonAuthorizationPath(path) ||
      matchAccessWith({
        list: access?.list ?? [],
        listType: access?.listType,
        method,
        path,
      }),
    isCurrentlyAuthorized: (state, { isAuthorizedFor }) =>
      isAuthorizedFor({ path: window.location.pathname }),
    loaded: (state) => !!state.email && !!state.sifiId,
    overdueReminders: ({ campaignReminders }) =>
      campaignReminders.filter(({ isOverdue }) => isOverdue),
    myCampaignIdsChecked: (state) => state.myCampaignIdsCheckedAt > 0,
    spendTableCampaignIdsChecked: (state) =>
      state.spendTableCampaignIdsChecked > 0,
    preferencesFor: (state) => (component) => {
      return state.preferences && state.preferences[component]
        ? state.preferences[component]
        : state.preferences || {}
    },
  },
  mutations: {
    campaignReminders(state, payload) {
      state.campaignReminders = payload
    },
    myCampaignIds(state, payload) {
      state.myCampaignIds = payload
      state.myCampaignIdsCheckedAt = Date.now()
    },
    spendTableCampaignIds(state, payload) {
      state.spendTableCampaignIds = payload
      state.spendTableCampaignIdsCheckedAt = Date.now()
    },
    preferences(state, { component, ...payload }) {
      if (typeof component === 'string') {
        state.preferences = {
          ...state.preferences,
          [component]: payload,
        }
      } else {
        state.preferences = {
          ...state.preferences,
          ...payload,
        }
      }
    },
    user(
      state,
      {
        access,
        email,
        experimentalFeaturesEnabled,
        isAdmin,
        limitFeatures,
        processId,
        role,
        sifiId,
      },
    ) {
      state.access = access
      state.email = email
      state.sifiId = sifiId
      state.isAdmin = isAdmin
      state.experimentalFeaturesEnabled = experimentalFeaturesEnabled
      state.isLoaded = true
      state.role = role
      state.limitFeatures = !!limitFeatures
      state.processId = processId
    },
  },
  actions: {
    async addToMyCampaigns({ dispatch }, campaignIds) {
      await manageMyCampaigns({
        action: 'add',
        campaignIds,
      })
      dispatch('getMyCampaignIds')
    },
    async addToSpendTable({ dispatch }, campaignIds) {
      await addCampaignsToSpendTable({ campaignIds })
      dispatch('getSpendTableCampaignIds')
    },
    async get({ commit }) {
      const user = await getCurrentUser()
      logger.info('get', user)
      if (!user) return false

      commit('user', {
        access: user.access,
        email: user.email,
        sifiId: user.sifiId,
        isAdmin: user.isAdmin,
        role: user.role,
        limitFeatures: user.limitFeatures,
        experimentalFeaturesEnabled:
          user.enable_experimental_features,
        processId: user.processId,
      })
      return true
    },
    async getIfNotLoaded({ dispatch, state }) {
      if (state.isLoaded) return true
      return dispatch('get')
    },
    async getTrackedCampaigns({ dispatch }) {
      logger.info('getTrackedCampaigns')
      dispatch('getMyCampaignIds')
      dispatch('getSpendTableCampaignIds')
    },
    async getMyCampaignIds({ commit }) {
      const {
        data: { campaignIds },
      } = await getUserMyCampaigns()
      commit('myCampaignIds', campaignIds)
    },
    async getSpendTableCampaignIds({ commit }) {
      const {
        data: { campaignIds },
      } = await getSpendTableCampaignIds()
      logger.info('getSpendTableCampaignIds', campaignIds)
      commit('spendTableCampaignIds', campaignIds)
    },
    async getMyReminders({ commit, state }, force = false) {
      if (!state.campaignReminders.length || force) {
        const {
          data: { campaignReminders },
        } = await getUserReminders()
        commit('campaignReminders', campaignReminders)
      }
    },
    async getPreferences({ commit }, component) {
      const {
        data: { preferences },
      } = await getPreferences(component)
      logger.info(`getPreferences: ${component}`, preferences)

      commit('preferences', {
        ...preferences,
        component,
      })
    },
    async removeFromMyCampaigns({ dispatch }, campaignIds) {
      await manageMyCampaigns({
        action: 'remove',
        campaignIds,
      })
      dispatch('getMyCampaignIds')
    },
    async removeFromSpendTable({ dispatch }, campaignIds) {
      await controlFlow.execute(
        campaignIds.map((id) => async () => removeFromSpendTable(id)),
        { parallel: 3 },
      )
      dispatch('getSpendTableCampaignIds')
    },
    async updatePreference({ dispatch }, { component, ...payload }) {
      await updatePreferences({ component, ...payload })

      dispatch('getPreferences', component)
    },
  },
}
